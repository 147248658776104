import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-button, [app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input()
  @HostBinding('attr.color')
  color: 'regular' | 'primary' | 'accent' | 'action' | 'progress' = 'regular';

  @Input()
  @HostBinding('attr.size')
  size: 'xs' | 's' | 'm' | 'l' | 'xl' = 'l';

  @Input()
  @HostBinding('style.animation-duration')
  progressDuration = '10s';
}
